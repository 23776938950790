import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M2341 6751 c-54 -51 -64 -106 -32 -178 36 -78 -24 -73 936 -73 943 0
891 -3 929 60 21 37 21 123 0 160 -38 63 15 60 -937 60 l-865 0 -31 -29z"/>
<path d="M4615 6762 c-139 -48 -265 -169 -316 -302 -18 -46 -99 -472 -99 -517
0 -23 2 -23 104 -23 l103 0 12 78 c7 42 15 85 17 95 5 15 23 17 188 17 100 0
185 -2 188 -5 3 -3 -1 -38 -8 -78 -22 -115 -29 -107 89 -107 l103 0 40 218
c26 148 39 246 39 307 0 82 -3 97 -33 157 -59 121 -157 178 -300 178 -46 -1
-97 -8 -127 -18z m175 -215 c52 -34 73 -78 72 -148 0 -32 -4 -64 -7 -69 -4 -6
-79 -10 -191 -10 -208 0 -198 -5 -168 82 45 133 200 209 294 145z"/>
<path d="M6998 6762 c-96 -3 -97 -3 -102 -30 -3 -15 -19 -106 -36 -202 -16
-96 -45 -263 -65 -370 -19 -107 -35 -205 -35 -218 0 -22 2 -23 103 -20 l102 3
17 90 c62 330 129 738 122 743 -5 4 -53 6 -106 4z"/>
<path d="M5110 6756 c0 -3 7 -35 16 -73 9 -37 47 -209 85 -382 56 -256 73
-319 94 -347 32 -42 84 -58 139 -43 65 18 108 69 202 237 174 312 334 604 334
608 0 2 -49 4 -110 4 -103 0 -110 -1 -123 -22 -8 -13 -36 -63 -64 -113 -171
-308 -216 -385 -224 -385 -5 0 -9 2 -9 5 0 6 -72 341 -95 443 l-17 72 -114 0
c-63 0 -114 -2 -114 -4z"/>
<path d="M6025 6751 c-3 -6 -16 -74 -30 -153 -41 -240 -85 -489 -100 -572 -22
-115 -30 -106 89 -106 l103 0 11 58 c21 107 82 457 88 503 4 26 10 52 14 59 5
9 52 12 171 11 157 0 165 -1 182 -23 23 -27 15 -65 -19 -95 -23 -21 -34 -23
-160 -23 -158 0 -145 21 -108 -183 27 -150 39 -180 101 -238 56 -53 111 -70
213 -67 l85 3 11 58 c7 32 15 78 19 102 l6 45 -76 0 c-68 0 -79 3 -101 25 -14
14 -24 30 -22 36 2 6 30 21 63 34 79 32 164 120 194 200 48 129 6 261 -101
316 -34 17 -62 19 -332 19 -172 0 -297 -4 -301 -9z"/>
<path d="M7195 6738 c-14 -65 -105 -606 -105 -626 0 -62 57 -142 125 -173 35
-16 67 -19 266 -19 l227 0 11 63 c6 34 13 81 17 105 l7 42 -200 0 c-152 0
-204 3 -218 14 -21 15 -25 -22 44 371 23 127 41 233 41 238 0 4 -47 7 -105 7
-100 0 -105 -1 -110 -22z"/>
<path d="M2175 5860 c-49 -24 -64 -48 -72 -109 -7 -60 16 -113 62 -141 32 -20
48 -20 885 -20 848 0 853 0 887 21 42 26 63 66 63 119 0 51 -22 99 -58 125
-26 20 -45 20 -877 23 -832 2 -851 2 -890 -18z"/>
<path d="M7532 5721 c-4 -11 -6 -54 -4 -95 l4 -76 71 0 c81 0 82 17 2 22 -46
3 -50 5 -53 31 -4 29 3 32 66 28 23 -2 32 2 32 13 0 12 -11 16 -50 16 -47 0
-50 2 -50 25 0 26 1 26 69 29 19 0 36 7 38 14 4 9 -12 12 -56 12 -52 0 -63 -3
-69 -19z"/>
<path d="M8108 5734 c-13 -12 -10 -186 3 -182 12 4 18 103 9 156 -3 19 -8 30
-12 26z"/>
<path d="M8659 5713 c-4 -127 -3 -157 8 -160 7 -3 13 10 15 34 3 37 5 38 43
41 47 4 75 26 75 58 0 33 -36 54 -92 54 -45 0 -48 -1 -49 -27z m95 -6 c29 -21
16 -51 -23 -55 -41 -4 -56 9 -47 42 7 29 40 35 70 13z"/>
<path d="M9782 5724 c-37 -26 -25 -55 35 -84 65 -32 69 -36 53 -55 -18 -22
-66 -19 -84 6 -8 12 -19 18 -25 15 -16 -10 -4 -30 30 -50 53 -31 109 -4 109
54 0 16 -13 27 -55 45 -59 27 -71 51 -29 61 18 5 31 0 48 -16 18 -16 26 -18
32 -9 10 17 -32 49 -65 49 -15 0 -37 -7 -49 -16z"/>
<path d="M10300 5730 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M8163 5645 c1 -47 6 -85 11 -85 4 0 10 38 12 85 3 68 1 85 -10 85
-11 0 -14 -18 -13 -85z"/>
<path d="M8512 5707 c-15 -17 -5 -129 13 -154 18 -24 39 -7 22 18 -11 15 -12
30 -4 70 8 41 7 53 -5 65 -13 13 -16 13 -26 1z"/>
<path d="M9616 5711 c-3 -5 -3 -43 0 -84 5 -54 11 -78 22 -82 22 -8 28 3 14
25 -15 24 -16 72 -2 86 8 8 7 19 -1 37 -13 28 -24 34 -33 18z"/>
<path d="M7690 5681 c0 -4 9 -20 21 -35 l21 -26 -22 -35 c-17 -28 -19 -35 -7
-35 8 0 17 7 21 15 3 8 12 15 21 15 9 0 18 -7 21 -15 4 -8 12 -15 20 -15 16 0
19 16 4 25 -26 16 -30 43 -10 68 28 35 26 56 -3 29 -29 -27 -39 -27 -55 -2
-12 20 -32 27 -32 11z"/>
<path d="M7853 5675 c-43 -30 -36 -105 12 -127 22 -10 30 -8 55 11 l30 22 30
-23 c24 -17 35 -20 57 -12 43 15 39 31 -8 30 -25 -1 -44 4 -46 12 -3 8 7 12
30 12 32 0 67 20 67 39 0 15 -47 51 -66 51 -27 0 -64 -40 -64 -68 0 -31 -62
-61 -85 -42 -19 16 -20 64 -1 79 12 10 20 9 39 -4 32 -21 46 -2 15 19 -28 20
-38 20 -65 1z m187 -20 c16 -19 6 -32 -25 -32 -14 0 -28 4 -31 9 -7 11 14 38
31 38 7 0 18 -7 25 -15z"/>
<path d="M8231 5664 c-28 -35 -26 -65 4 -98 27 -29 32 -30 70 -16 36 14 22 30
-19 23 -21 -3 -36 -1 -41 7 -10 16 12 29 44 25 50 -5 65 26 31 63 -28 30 -64
28 -89 -4z m69 -14 c0 -15 -7 -20 -25 -20 -24 0 -34 18 -18 33 15 15 43 7 43
-13z"/>
<path d="M8398 5683 c-26 -4 -28 -8 -29 -54 -1 -27 -2 -54 -3 -61 -1 -6 5 -13
12 -15 8 -3 12 9 12 39 0 52 18 82 43 72 13 -5 17 -18 17 -61 0 -38 4 -53 12
-51 8 3 11 24 9 64 -3 55 -13 76 -36 72 -5 0 -22 -3 -37 -5z"/>
<path d="M8844 5676 c-26 -20 -10 -32 21 -16 19 10 28 11 37 2 18 -18 1 -42
-30 -42 -51 0 -69 -39 -29 -64 15 -10 34 -12 62 -7 l40 7 -3 54 c-4 58 -19 80
-57 80 -12 0 -30 -6 -41 -14z m64 -89 c3 -13 -4 -17 -27 -17 -34 0 -44 25 -13
33 25 7 37 2 40 -16z"/>
<path d="M8960 5682 c0 -4 9 -31 20 -60 22 -56 26 -102 10 -102 -5 0 -10 -7
-10 -15 0 -20 14 -19 32 3 12 14 68 152 68 168 0 2 -6 4 -13 4 -8 0 -19 -18
-26 -40 -6 -22 -16 -40 -21 -40 -5 0 -15 18 -21 39 -7 22 -18 42 -25 45 -8 3
-14 2 -14 -2z"/>
<path d="M9128 5683 c-27 -4 -28 -6 -28 -69 0 -50 3 -64 15 -64 11 0 15 12 15
49 0 32 5 53 15 61 24 20 39 -2 40 -57 0 -71 21 -65 28 9 3 35 5 63 4 64 -7 6
-67 10 -89 7z"/>
<path d="M9230 5680 c0 -5 9 -14 20 -20 16 -9 20 -21 20 -61 0 -36 4 -49 13
-47 19 7 21 86 2 114 -15 23 -55 34 -55 14z"/>
<path d="M9340 5670 c-21 -21 -26 -69 -10 -100 12 -23 55 -34 80 -20 33 17 23
28 -20 23 -36 -5 -40 -3 -40 16 0 19 4 21 33 16 25 -5 37 -2 51 14 18 19 17
21 -8 45 -30 31 -59 33 -86 6z m67 -16 c5 -14 -11 -34 -27 -34 -3 0 -11 4 -19
9 -19 12 -6 41 19 41 11 0 23 -7 27 -16z"/>
<path d="M9498 5683 c-27 -4 -28 -6 -28 -69 0 -82 23 -84 30 -3 4 46 8 54 25
54 17 0 20 -8 23 -57 2 -43 7 -58 17 -58 22 0 21 103 -1 124 -8 9 -21 15 -27
15 -7 -1 -24 -4 -39 -6z"/>
<path d="M9950 5672 c-22 -18 -31 -76 -15 -102 12 -20 53 -30 80 -20 38 15 30
29 -14 23 -25 -3 -41 -1 -46 7 -10 17 15 30 43 23 24 -6 52 11 52 33 0 20 -37
54 -59 54 -10 0 -29 -8 -41 -18z m68 -23 c2 -12 -6 -18 -28 -21 -37 -6 -42 -1
-29 24 12 23 52 21 57 -3z"/>
<path d="M10103 5683 c-21 -4 -23 -10 -23 -69 0 -36 4 -64 10 -64 6 0 10 22
10 48 0 26 5 53 12 60 26 26 45 12 69 -48 15 -39 29 -60 38 -58 13 3 48 84 50
116 2 28 -19 9 -30 -28 -15 -51 -33 -51 -47 -1 -11 34 -37 56 -62 50 -3 -1
-15 -4 -27 -6z"/>
<path d="M10370 5670 c-23 -23 -27 -78 -7 -102 21 -27 52 -30 80 -8 l26 21 31
-22 c24 -18 35 -21 57 -13 43 15 38 31 -9 30 -32 -1 -43 3 -43 14 0 11 9 14
36 12 48 -4 68 18 49 54 -8 13 -24 27 -37 30 -31 8 -73 -20 -73 -49 0 -27 -8
-38 -37 -54 -32 -18 -51 -16 -63 8 -25 46 15 96 50 64 23 -21 36 -2 14 19 -21
22 -50 20 -74 -4z m194 -16 c8 -22 -1 -34 -28 -34 -30 0 -37 9 -25 31 12 23
45 25 53 3z"/>
<path d="M10636 5674 c-26 -25 -18 -44 24 -62 22 -9 40 -23 40 -29 0 -16 -42
-17 -58 -1 -6 6 -15 8 -19 4 -9 -9 33 -46 52 -46 7 0 23 8 36 18 29 24 20 48
-26 68 -19 8 -35 21 -35 29 0 18 14 19 42 4 27 -14 38 -1 14 17 -26 18 -51 18
-70 -2z"/>
<path d="M10300 5625 c0 -30 0 -58 -1 -62 0 -4 4 -9 10 -11 7 -2 11 20 11 62
0 37 -4 66 -10 66 -5 0 -10 -25 -10 -55z"/>
<path d="M10765 5560 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M10830 5560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M10910 5560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M10980 5560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M11050 5560 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
